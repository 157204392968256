<template>
    <!-- 导航 -->
    <page-head :title="name" />
    <content-bg v-if="videoSrc">
        <template v-slot:content>
            <video
                ref="myPlayer"
                :src="videoSrc"
                :controls="videoControls"
                autoplay
                disablePictureInPicture
                allowfullscreen="true"
                webkitallowfullscreen="true"
                oncontextmenu="return false"
                mozallowfullscreen="true" />
        </template>
    </content-bg>
</template>

<script>
import { useRoute } from "vue-router";
import { reactive, toRefs, defineComponent } from "vue";

export default defineComponent({
    setup() {

        const route = useRoute();
        // const { proxy } = getCurrentInstance();
        const { url, name } = route.query;

        const state = reactive({
            // id,
            name,
            videoSrc: url,//原始视频路径
            videoControls: true, //视频控制条是否显示
        });

        // const getDetailsData = async () => {
        //     const params = {
        //         videoId: state.id
        //     }
        //     const { code, data } = await proxy.$server.getVideo(params);
        //     if (code === 200) {
        //         state.videoSrc = data.url;
        //     }
        // };

        // onMounted(() => {
        //     getDetailsData()
        // })


        return {
            ...toRefs(state)
        }
    }
})
</script>

<style scoped lang="scss">
:deep(.contentBg){
    overflow: hidden !important;
}
video {
    width: 100%;
    height: 100%;
    border-radius: 10px;
}

// 全屏禁用
video::-webkit-media-controls-fullscreen-button {
    display: none !important;
}
// 更多选项
video::-webkit-media-controls:not(.audio-only) input[pseudo="-webkit-media-controls-timeline" i]::-internal-track-segment-highlight-before  {
    display: none !important;
}
video::-internal-media-controls-button-hover-background {
    display: none !important;
}
</style>